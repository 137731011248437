import React from "react";
import { ContentContainer } from "pages/common/styles";
import { Heading, PageContainer } from "./styles";
import {
  usePatientInterest,
  PatientInterestProvider,
} from "context/patient-interest";
import { useRiskResultsKiosk } from "hooks/use-kiosk-risk-results";
import { InterestModal } from "components/interest-modal";
import { ResultsHeader } from "components/results-header";
import { KioskLongSurvey } from "../kiosk-long-survey";

function PatientHubPage() {
  const results = useRiskResultsKiosk();
  const { modalType } = usePatientInterest();

  const sessionId = localStorage.getItem(
    "ciq-questionnaire.sessionId"
  ) as string;

  if (!sessionId) {
    console.error("No session ID found in local storage");
    // TODO: Style error messages, though this is an edge case
    return <div>Error loading survey</div>;
  }

  return (
    <PageContainer data-id="PageContainer">
      <ContentContainer>
        <ResultsHeader />
        <Heading>Patient Hub</Heading>
        <KioskLongSurvey userId={sessionId} />
      </ContentContainer>
      {modalType && <InterestModal riskResults={results} isRemote={false} />}
    </PageContainer>
  );
}

export function PatientHub() {
  return (
    <PatientInterestProvider>
      <PatientHubPage />
    </PatientInterestProvider>
  );
}
