import styled from "styled-components";
import { tablet } from "styles/breakpoints";

export const InfoContainer = styled.div`
  flex: 2;
  gap: 16px;
  display: flex;
  max-width: 780px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;

  > svg {
    margin-top: 32px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  @media (min-width: ${tablet}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ReasonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IconWrapper = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;

  && {
    button {
      text-transform: none;
    }
  }
`;

export const UL = styled.ul`
  margin: 0;

  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;
