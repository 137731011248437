import styled, { createGlobalStyle } from "styled-components";
import { tablet } from "styles/breakpoints";

export const GlobalStyle = createGlobalStyle`
  body {
    scrollbar-gutter: stable;
  }
`;

export const SectionContent = styled.section`
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin: 16px auto 0 auto;
  flex-direction: column;
  padding: 0 16px 16px 16px;

  @media (min-width: ${tablet}px) {
    align-items: flex-start;
  }

  // Temporary overrides until new Typography designs are implemented
  && {
    > h2 {
      color: #4a4d5e;
      font-size: 20px;
      font-weight: 700;

      @media (min-width: ${tablet}px) {
        font-size: 28px;
      }
    }
  }

  .risk-summary {
    width: 100%;
    margin: 8px 0 26px 0;
  }

  &.video {
    h2 {
      margin: 0 0 15px 0;
      padding-right: 30px;
    }
  }

  .accordionTitle {
    color: #798ecf;
    font-size: 26px;
    font-family: Noto Sans;
  }
`;

export const PageHeading = styled.h1`
  position: absolute;
  left: -10000px;
  top: 0px;
`;

export const ButtonContainer = styled.div<{ $show?: boolean }>`
  position: ${({ $show }) => ($show ? "fixed" : "none")};
  bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 1200px) {
    width: 1200px;
  }

  && {
    margin: 0 auto;
    button {
      background-color: rgba(255, 255, 255, 0.8);
      text-transform: none;
    }
  }
`;
