export function CareIcon() {
  return (
    <svg
      width="399"
      height="90"
      viewBox="0 0 399 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="18.5927" width="398.522" height="52.8607" fill="#CAEBFF" />
      <path
        d="M122.843 79.5374H61.5159V44.2913H122.843V79.5374Z"
        fill="white"
      />
      <path
        d="M98.1719 4.81605H79.1392C62.3987 4.81605 50.2375 18.387 50.2375 35.1274C50.2375 51.8685 62.3987 65.4387 79.1392 65.4387H79.844"
        fill="white"
      />
      <path
        d="M98.1719 4.81605H79.1392C62.3987 4.81605 50.2375 18.387 50.2375 35.1274C50.2375 51.8685 62.3987 65.4387 79.1392 65.4387H79.844"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.483 35.1274C128.483 51.8685 114.912 65.4387 98.1717 65.4387C81.4313 65.4387 67.8604 51.8685 67.8604 35.1274C67.8604 18.387 81.4313 4.81605 98.1717 4.81605C114.912 4.81605 128.483 18.387 128.483 35.1274Z"
        fill="white"
      />
      <path
        d="M115.09 35.1274C115.09 44.4712 107.516 52.0456 98.1718 52.0456C88.8281 52.0456 81.2537 44.4712 81.2537 35.1274C81.2537 25.7843 88.8281 18.2099 98.1718 18.2099C107.516 18.2099 115.09 25.7843 115.09 35.1274Z"
        fill="#9F9FFF"
      />
      <path
        d="M134.123 42.8816H89.0077C87.8395 42.8816 86.8931 43.8288 86.8931 44.9962V47.7374C89.8862 50.4169 93.8388 52.0456 98.1716 52.0456H134.123C135.29 52.0456 136.237 51.0991 136.237 49.931V44.9962C136.237 43.8288 135.29 42.8816 134.123 42.8816Z"
        fill="white"
      />
      <path
        d="M134.123 42.8816H89.0077C87.8395 42.8816 86.8931 43.8288 86.8931 44.9962V47.7374C89.8862 50.4169 93.8388 52.0456 98.1716 52.0456H134.123C135.29 52.0456 136.237 51.0991 136.237 49.931V44.9962C136.237 43.8288 135.29 42.8816 134.123 42.8816Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.811 5.34799C101.983 5.00414 100.1 4.81608 98.1718 4.81608C96.2439 4.81608 94.3606 5.00414 92.5322 5.34799V9.75084C92.5322 11.3081 93.7951 12.5703 95.3524 12.5703H100.991C102.549 12.5703 103.811 11.3081 103.811 9.75084V5.34799Z"
        fill="#9F9FFF"
      />
      <path
        d="M103.811 5.34799C101.983 5.00414 100.1 4.81608 98.1718 4.81608C96.2439 4.81608 94.3606 5.00414 92.5322 5.34799V9.75084C92.5322 11.3081 93.7951 12.5703 95.3524 12.5703H100.991C102.549 12.5703 103.811 11.3081 103.811 9.75084V5.34799Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.8767 37.5948C98.8767 40.5145 96.5095 42.8817 93.5898 42.8817C90.6702 42.8817 88.303 40.5145 88.303 37.5948C88.303 34.6751 90.6702 32.308 93.5898 32.308C96.5095 32.308 98.8767 34.6751 98.8767 37.5948Z"
        fill="white"
      />
      <path
        d="M98.8767 37.5948C98.8767 40.5145 96.5095 42.8817 93.5898 42.8817C90.6702 42.8817 88.303 40.5145 88.303 37.5948C88.303 34.6751 90.6702 32.308 93.5898 32.308C96.5095 32.308 98.8767 34.6751 98.8767 37.5948Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.909 42.8817H105.926C104.758 42.8817 103.811 41.9352 103.811 40.7671V36.5372C103.811 35.3697 104.758 34.4226 105.926 34.4226H117.204C117.594 34.4226 117.909 34.7383 117.909 35.1274V42.8817Z"
        fill="#FF9D6C"
      />
      <path
        d="M117.909 42.8817H105.926C104.758 42.8817 103.811 41.9352 103.811 40.7671V36.5372C103.811 35.3697 104.758 34.4226 105.926 34.4226H117.204C117.594 34.4226 117.909 34.7383 117.909 35.1274V42.8817Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.598 35.8323V36.5372H117.909V42.8817H131.303H135.532V36.5372V32.308H134.123C132.176 32.308 130.598 33.8858 130.598 35.8323Z"
        fill="#FF9D6C"
      />
      <path
        d="M130.598 35.8323V36.5372H117.909V42.8817H131.303H135.532V36.5372V32.308H134.123C132.176 32.308 130.598 33.8858 130.598 35.8323Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.598 36.5372V42.8817"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.6138 32.3079C75.6138 32.6971 75.2988 33.0128 74.909 33.0128C74.5198 33.0128 74.2041 32.6971 74.2041 32.3079C74.2041 31.9188 74.5198 31.6031 74.909 31.6031C75.2988 31.6031 75.6138 31.9188 75.6138 32.3079Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.7944 37.9476H76.3195"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.696 65.2363C100.54 65.3701 99.3639 65.4387 98.1717 65.4387C81.4313 65.4387 67.8604 51.8685 67.8604 35.1274C67.8604 18.387 81.4313 4.81605 98.1717 4.81605C113.44 4.81605 126.071 16.1049 128.175 30.7911"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.1718 52.0456C88.8281 52.0456 81.2537 44.4712 81.2537 35.1274C81.2537 25.7843 88.8281 18.2099 98.1718 18.2099C105.763 18.2099 112.187 23.2098 114.329 30.0965"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.4827 62.6632V79.5374"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.5159 59.4202V79.5374"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.237 79.5375H53.7617V85.1771H136.237V79.5375Z"
        fill="#9F9FFF"
      />
      <path
        d="M136.237 79.5375H53.7617V85.1771H136.237V79.5375Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.843 52.0455V79.5374"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.696 79.5374V52.0455"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.712 51.3441H154.499C152.942 51.3441 151.68 50.082 151.68 48.5247V4.82015C151.68 3.26285 152.942 2.00001 154.499 2.00001H213.712C215.27 2.00001 216.533 3.26285 216.533 4.82015V48.5247C216.533 50.082 215.27 51.3441 213.712 51.3441Z"
        fill="white"
      />
      <path
        d="M213.712 51.3441H154.499C152.942 51.3441 151.68 50.082 151.68 48.5247V4.82015C151.68 3.26285 152.942 2.00001 154.499 2.00001H213.712C215.27 2.00001 216.533 3.26285 216.533 4.82015V48.5247C216.533 50.082 215.27 51.3441 213.712 51.3441Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.237 59.8033H193.975V51.3442H174.237V59.8033Z"
        fill="#FF9D6C"
      />
      <path
        d="M174.237 59.8033V51.3442H193.975V59.8033"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.319 88V61.2137C157.319 60.4347 157.95 59.8033 158.729 59.8033H209.483C210.261 59.8033 210.893 60.4347 210.893 61.2137V88"
        fill="#9F9FFF"
      />
      <path
        d="M157.319 88V61.2137C157.319 60.4347 157.95 59.8033 158.729 59.8033H209.483C210.261 59.8033 210.893 60.4347 210.893 61.2137V88"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.827 70.3769C172.827 73.1023 170.618 75.3117 167.892 75.3117C165.168 75.3117 162.958 73.1023 162.958 70.3769C162.958 67.6522 165.168 65.4428 167.892 65.4428C170.618 65.4428 172.827 67.6522 172.827 70.3769Z"
        fill="white"
      />
      <path
        d="M172.827 70.3769C172.827 73.1023 170.618 75.3117 167.892 75.3117C165.168 75.3117 162.958 73.1023 162.958 70.3769C162.958 67.6522 165.168 65.4428 167.892 65.4428C170.618 65.4428 172.827 67.6522 172.827 70.3769Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.696 66.8525H189.041"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.696 73.9019H189.041"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.5 66.8525H203.843"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.5 73.9019H203.843"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.253 80.9513H210.893"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M226.643 29.7342C228.845 27.5317 232.416 27.5317 234.618 29.7342"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.157 25.2476C226.836 20.5681 234.409 20.5826 239.09 25.2627"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M238.68 43.2955C238.961 43.0134 239.094 42.6201 239.064 42.2227C238.726 37.8604 235.079 34.4267 230.63 34.4267C226.152 34.4267 222.486 37.9064 222.191 42.3099C222.166 42.6764 222.319 43.0326 222.579 43.2927L224.956 45.6695C225.881 46.5954 226.401 47.85 226.401 49.1589V53.4594C226.401 55.795 228.295 57.6886 230.63 57.6886C232.966 57.6886 234.86 55.795 234.86 53.4594V49.1589C234.86 47.85 235.38 46.5954 236.305 45.6695L238.68 43.2955Z"
        fill="#FF9D6C"
      />
      <path
        d="M238.68 43.2955C238.961 43.0134 239.094 42.6201 239.064 42.2227C238.726 37.8604 235.079 34.4267 230.63 34.4267C226.152 34.4267 222.486 37.9064 222.191 42.3099C222.166 42.6764 222.319 43.0326 222.579 43.2927L224.956 45.6695C225.881 46.5954 226.401 47.85 226.401 49.1589V53.4594C226.401 55.795 228.295 57.6886 230.63 57.6886C232.966 57.6886 234.86 55.795 234.86 53.4594V49.1589C234.86 47.85 235.38 46.5954 236.305 45.6695L238.68 43.2955Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.63 57.6887V82.7131C230.63 85.6335 228.263 88 225.343 88C222.424 88 220.057 85.6335 220.057 82.7131V72.8443C220.057 69.9246 217.689 67.5575 214.77 67.5575H210.893"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M226.401 49.2295H229.925"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M210.893 18.9182V7.63962H199.614"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.597 7.63962H157.319V18.9182"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.614 45.7052H210.893V34.4266"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.319 34.4266V45.7052H168.597"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M207.035 28.4534C194.371 41.117 173.84 41.117 161.177 28.4534L174.635 14.995C179.866 20.2256 188.346 20.2256 193.577 14.995L207.035 28.4534Z"
        fill="#9F9FFF"
      />
      <path
        d="M207.035 28.4534C194.371 41.117 173.84 41.117 161.177 28.4534L174.635 14.995C179.866 20.2256 188.346 20.2256 193.577 14.995L207.035 28.4534Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185.516 28.0822C185.516 28.8612 184.885 29.4919 184.106 29.4919C183.327 29.4919 182.696 28.8612 182.696 28.0822C182.696 27.3039 183.327 26.6725 184.106 26.6725C184.885 26.6725 185.516 27.3039 185.516 28.0822Z"
        stroke="#36275B"
        strokeWidth="2.05036"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M322.954 51.3149H263.776C262.22 51.3149 260.958 50.0528 260.958 48.4965V4.81785C260.958 3.26148 262.22 2.00005 263.776 2.00005H322.954C324.511 2.00005 325.773 3.26148 325.773 4.81785V48.4965C325.773 50.0528 324.511 51.3149 322.954 51.3149Z"
        fill="white"
      />
      <path
        d="M322.954 51.3149H263.776C262.22 51.3149 260.958 50.0528 260.958 48.4965V4.81785C260.958 3.26148 262.22 2.00005 263.776 2.00005H322.954C324.511 2.00005 325.773 3.26148 325.773 4.81785V48.4965C325.773 50.0528 324.511 51.3149 322.954 51.3149Z"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M283.502 59.769H303.228V51.315H283.502V59.769Z" fill="#9F9FFF" />
      <path
        d="M283.502 59.769V51.315H303.228V59.769"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M266.594 87.9491V61.1779C266.594 60.3994 267.225 59.769 268.003 59.769H318.728C319.505 59.769 320.136 60.3994 320.136 61.1779V87.9491"
        fill="#9F9FFF"
      />
      <path
        d="M266.594 87.9491V61.1779C266.594 60.3994 267.225 59.769 268.003 59.769H318.728C319.505 59.769 320.136 60.3994 320.136 61.1779V87.9491"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.093 70.3364C282.093 73.0596 279.885 75.2675 277.162 75.2675C274.438 75.2675 272.23 73.0596 272.23 70.3364C272.23 67.6126 274.438 65.4046 277.162 65.4046C279.885 65.4046 282.093 67.6126 282.093 70.3364Z"
        fill="white"
      />
      <path
        d="M282.093 70.3364C282.093 73.0596 279.885 75.2675 277.162 75.2675C274.438 75.2675 272.23 73.0596 272.23 70.3364C272.23 67.6126 274.438 65.4046 277.162 65.4046C279.885 65.4046 282.093 67.6126 282.093 70.3364Z"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M291.957 66.8135H298.297"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M291.957 73.8586H298.297"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M306.751 66.8135H313.092"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M306.751 73.8586H313.092"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.526 80.9038H320.137"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M339.862 32.9979V82.6653C339.862 85.5833 337.497 87.9491 334.579 87.9491C331.661 87.9491 329.295 85.5833 329.295 82.6653V72.8017C329.295 69.8837 326.929 67.518 324.011 67.518H320.136"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M339.863 25.2921V16.0897"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M344.794 26.6572L348.317 18.2031"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M334.93 26.6572L331.408 18.2031"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M320.137 18.9076V7.63568H308.865"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M277.866 7.63568H266.594V18.9076"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M308.865 45.6786H320.137V34.4067"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M266.594 34.4067V45.6786H277.866"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M280.269 45.6786L278.091 33.6358C277.553 30.4098 279.398 27.2614 282.474 26.1536L286.425 24.7317C291.435 22.9277 294.774 18.1763 294.774 12.8521V7.63561H300.411V11.8623L303.515 12.8178C307.113 13.9249 309.569 17.2503 309.569 21.0154V21.016C309.569 27.2086 306.579 33.0205 301.539 36.6202L299.867 37.8144C296.705 40.0725 292.594 40.517 289.023 38.9867L286.096 37.7321C285.584 37.5126 285.032 37.9461 285.123 38.4956L286.32 45.6786"
        fill="#FF9D6C"
      />
      <path
        d="M279.276 40.7476L278.091 33.6358C277.553 30.4099 279.398 27.2615 282.474 26.1537L286.425 24.7318C291.435 22.9278 294.774 18.1764 294.774 12.8522V7.6357"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M300.411 7.63561V11.8623L303.514 12.8178C307.113 13.9249 309.569 17.2503 309.569 21.0154V21.016C309.569 27.2086 306.578 33.0205 301.539 36.6202L299.867 37.8144C296.705 40.0725 292.594 40.517 289.023 38.9867L286.096 37.7321C285.584 37.5126 285.032 37.9461 285.123 38.4956L286.32 45.6786"
        stroke="#36275B"
        strokeWidth="2.61234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
