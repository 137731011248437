import { useCallback, useState, useEffect } from "react";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { Video } from "components/video";
import { Advert } from "components/advert";
import { InterestModal } from "components/interest-modal";
import { useRiskProfile } from "context/risk-profile";
import { ModalType, usePatientInterest } from "context/patient-interest";
import { useMediaQuery } from "@material-ui/core";
import { mobile } from "styles/breakpoints";
import {
  UL,
  ContentWrapper,
  InfoColumn,
  InfoContainer,
  ReasonsContainer,
  ButtonContainer,
} from "./styles";
import { SectionContent } from "../../styles";
import { CareIcon } from "./icons/care";

export function NextStepsSection() {
  const riskProfile = useRiskProfile();
  const { setModalType, interestLevel } = usePatientInterest();
  const [showModal, setShowModal] = useState(false);
  const [portraitVideoAvailable, setPortraitVideoAvailable] = useState(false);

  const riskResult = riskProfile?.riskResult;

  const handleRequestClick = useCallback(() => {
    setModalType(ModalType.INITIAL);
    setShowModal(true);
  }, [setModalType]);

  const isMobileView = useMediaQuery(`(max-width:${mobile}px)`);

  const videoUrl = riskResult?.videoUrl || "";
  const splitUrl = videoUrl.split("/");
  const fileName = splitUrl[splitUrl.length - 1];
  const portraitFileName = fileName.replace(".mp4", "_portrait.mp4");
  const portraitVideoUrl = videoUrl.replace(fileName, portraitFileName);

  useEffect(() => {
    const fetchVideo = async () => {
      const response = await fetch(portraitVideoUrl);

      if (response.ok) {
        setPortraitVideoAvailable(true);
      }
    };

    fetchVideo();
  }, [portraitVideoUrl]);

  if (!riskResult) {
    return null;
  }

  return (
    <SectionContent className="video">
      <Typography theme="default" variant="h5" component="h2" color="black">
        {riskResult.nextStepsHeader
          ? riskResult.nextStepsHeader
          : "You're eligible for further testing/screening, please take action!"}
      </Typography>
      <ContentWrapper>
        <InfoColumn>
          {riskResult.videoEnabled ? (
            <InfoContainer>
              <Video
                src={
                  isMobileView && portraitVideoAvailable
                    ? portraitVideoUrl
                    : riskResult.videoUrl
                }
              />
            </InfoContainer>
          ) : (
            <InfoContainer>
              <CareIcon />
              <ReasonsContainer>
                <UL>
                  <li>
                    <b>Eligibility Confirmation:</b> Based on your survey
                    results, you are eligible for further testing/screening.
                    Please take action to prioritize your health.
                  </li>
                  <li>
                    <b>Focus on Prevention:</b> Early detection can
                    significantly improve outcomes, especially for conditions
                    like cancer. The statement encourages proactive health
                    management.
                  </li>
                  <li>
                    <b>Timely Action Matters:</b> Delaying follow-up could
                    increase risks. Acting now allows for proactive steps to
                    safeguard your health.
                  </li>
                  <li>
                    <b>Empower Your Health Journey:</b> Understanding your
                    genetic risks helps you make informed decisions about your
                    health and future, including possible lifestyle changes or
                    preventative strategies.
                  </li>
                </UL>
              </ReasonsContainer>
            </InfoContainer>
          )}
          {riskResult.showScheduler && (
            <ButtonContainer>
              <Button
                size="large"
                theme="default"
                fullWidth={true}
                color="secondary"
                variant="contained"
                onClick={handleRequestClick}
                disabled={interestLevel !== null}
              >
                {riskResult.callToActionText
                  ? riskResult.callToActionText
                  : "Explore Your Options"}
              </Button>
            </ButtonContainer>
          )}
        </InfoColumn>
        <Advert
          ciqTargetedContent="true"
          ciqInventoryClass="display"
          ciqClass="with-background"
          ciqShowTargetedContent="true"
          ciqCanvas="targeted-content-canvas"
        />
      </ContentWrapper>
      {showModal && <InterestModal riskResults={riskResult} isRemote={true} />}
    </SectionContent>
  );
}
