import React, { useState, useCallback } from "react";
import { NAVIGATOR_HOSTNAME } from "env-vars";
import {
  ErrorType,
  ModalType,
  InterestLevel,
  usePatientInterest,
} from "context/patient-interest";
import { EmailForm } from "./email-form";
import { CalendarForm } from "./calendar-form";
import { Confirmation } from "./confirmation";
import { useRiskProfile } from "context/risk-profile";
import { useRiskResultsKiosk } from "hooks/use-kiosk-risk-results";

// eslint-disable-next-line react-refresh/only-export-components
export enum FormSection {
  EMAIL = "EMAIL",
  CALENDAR = "CALENDAR",
  CONFIRMATION = "CONFIRMATION",
}

const formFieldsMap = {
  [FormSection.EMAIL]: EmailForm,
  [FormSection.CALENDAR]: CalendarForm,
  [FormSection.CONFIRMATION]: Confirmation,
};

type Props = {
  onClose: () => void;
  isRemote: boolean;
};

export function ScheduleModal({ onClose, isRemote }: Props) {
  const kioskResults = useRiskResultsKiosk();
  const patientRiskProfile = useRiskProfile();
  const { setModalType, setErrorType, setInterestLevel } = usePatientInterest();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [enteredEmail, setEnteredEmail] = useState<string | undefined>();
  const [dates, setDates] = useState<Date[]>([]);

  // Both remote and kiosk patients can schedule appointments
  let results: any;
  if (patientRiskProfile) {
    results = patientRiskProfile.riskResult;
  } else {
    results = kioskResults;
  }

  const [formSection, setFormSection] = useState<FormSection>(() =>
    isRemote ? FormSection.CALENDAR : FormSection.EMAIL
  );

  const handleConfirm = useCallback(async () => {
    setSubmitting(true);

    const auth0UserEmail = localStorage.getItem("ciq_email");

    // epoch (reliant on client's sys clock)
    const currentTime = Math.floor(new Date().getTime());

    const inPersonActionType = 17;

    const timeSlots = dates.map((date) => {
      const startTime = date.getTime();
      const stopTime = startTime + 3600000; // Adding 1 hour

      return {
        start_time: startTime,
        stop_time: stopTime,
      };
    });

    const payload = {
      person: {
        preferred_appointment_times_attributes: timeSlots,
        person_statuses_attributes: [
          {
            status: 21, // Means "IN_PERSON_INTERESTED"
          },
        ],
        person_contact_histories_attributes: [
          {
            actionDateTime: currentTime,
            actionType: inPersonActionType,
            in_person_reason: 1, // Means "IN_PERSON_INTERESTED"
            user_email: auth0UserEmail || enteredEmail || "",
          },
        ],
      },
    };

    try {
      const idToken = localStorage.getItem("id_token");

      // Patient id from a Kiosk session
      const kioskPatientId = localStorage.getItem(
        "ciq-questionnaire.sessionId"
      );
      // Patient id from a remote session
      const remotePatientId = localStorage.getItem("ciq_id");

      // Derive the correct patient id
      const patientId = isRemote ? remotePatientId : kioskPatientId;

      const response = await fetch(
        `${NAVIGATOR_HOSTNAME}/api/v1/people/${patientId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        console.error("Failed to submit form");

        setSubmitting(false);

        // TODO: Handle 401 errors, call Auth0 logout

        setErrorType(ErrorType.GENERAL);

        return;
      }

      setSubmitting(false);

      if (results?.lockPatientHub && results?.showPatientHub) {
        setModalType(ModalType.LOCK_PATIENT_HUB);
        return;
      }

      if (results?.showPatientHub) {
        window.location.href = "/institution/patient-hub";
      }

      // This is done to ensure the Continue button is not shown after submission
      setInterestLevel(InterestLevel.INTERESTED);
      setModalType(ModalType.THANK_YOU);
    } catch (error) {
      setSubmitting(false);
      console.error("Error submitting form: ", error);
    }
  }, [
    dates,
    isRemote,
    enteredEmail,
    setErrorType,
    setModalType,
    setInterestLevel,
    results?.lockPatientHub,
    results?.showPatientHub,
  ]);

  const handleContinue = useCallback(
    (formData, completedSection: FormSection) => {
      if (completedSection === FormSection.EMAIL) {
        if (formData?.email) {
          setEnteredEmail(enteredEmail);
        }

        setFormSection(FormSection.CALENDAR);
      } else if (completedSection === FormSection.CALENDAR) {
        if (formData?.dates.length) {
          setDates(formData.dates);
        }

        setFormSection(FormSection.CONFIRMATION);
      } else if (completedSection === FormSection.CONFIRMATION) {
        handleConfirm();

        return;
      }
    },
    [enteredEmail, handleConfirm]
  );

  const handleEdit = useCallback(() => {
    setErrorType(null);
    setModalType(ModalType.CALENDAR);
    setFormSection(FormSection.CALENDAR);
  }, [setErrorType, setModalType]);

  const Form = formFieldsMap[formSection];

  return (
    <Form
      dates={dates}
      onClose={onClose}
      onEdit={handleEdit}
      onContinue={handleContinue}
      isSubmitting={isSubmitting}
    />
  );
}
